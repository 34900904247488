@import "var"; // variables import
@import "header";
@import "theme";

* {
  scroll-margin-top: 120px;
}

body {
  padding: 0;
  background-color: #{$appBg};
  height: 100%;
}
p {
  margin: 0;
}
main {
  color: #{$appTextcolor};
  flex: 4;
  overflow: scroll;
  padding: 0 12em;
}
@font-face {
  font-family: "pfi";
  src: url(../images/font.woff) format("woff");
}
[class*="pfi"],
[class^="pfi"] {
  font-family: "pfi";
  display: inline-block;
  font-style: normal;
}

#{$pfi}-mail:before {
  content: "\e900";
}
#{$pfi}-linkedin:before {
  content: "\e901";
}
#{$pfi}-insta:before {
  content: "\e902";
}
#{$pfi}-github:before {
  content: "\e903";
}

#{$pf}-about {
  &__hello {
    margin-top: 90px;
  }
  &__name {
    margin-top: 30px;
  }
  &__desc {
    margin-top: 40px;
  }
  &__button {
    margin-top: 40px;
  }
}
#{$pf}-skills {
  margin-top: 100px;
  &-cat-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-cat-item {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-right: 70px;
    flex: 1;
  }
  &-list {
    display: flex;
    margin-top: 40px;
    flex-direction: column;
  }
  &-item {
    display: flex;
    margin-top: 30px;
    align-items: center;
    &:first-child {
      margin-top: 0;
    }
    &__icon {
      width: 48px;
      height: 48px;
      background-color: #{$appSkilliconbg};
      box-shadow: 0 0 4px #ddd;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 30px;
      }
    }
    &__desc {
      margin-left: 20px;
    }
  }
}
#{$pf}-experience {
  margin-top: 100px;
  &-organization {
    margin-top: 50px;
    &-details {
      &__logo {
        height: 32px;
        margin: 0;
      }
      &-role-name-wra {
        margin-top: 30px;
        display: flex;
        font-weight: 600;
        font-size: 16px;
      }
      &__role {
        margin: 0;
      }
      &__name {
        margin: 0;
      }
      &-duration-wra {
        margin-top: 10px;
        display: flex;
      }
      &__timeduration {
        margin: 0;
      }
      &__timeperiod {
        margin: 0;
      }
    }
    &-responsibility {
      margin-top: 30px;
      padding: 0 0 0 15px;
      li {
        margin-top: 10px;
      }
    }
    &-projects {
      margin-top: 60px;
      &-item {
        margin-top: 40px;
        &:first-child {
          margin-top: 0;
        }
        &-header {
          display: flex;
        }
        &__logo {
          height: 48px;
          width: 48px;
          border-radius: 6px;
          background-color: #{$appSkilliconbg};
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          box-shadow: 0 0 4px #ddd;
          img {
            height: 30px;
          }
        }
        &__title {
          font-weight: 600;
          margin-bottom: 10px;
        }
        &__url {
          color: #{$appTextcolor};
          &:visited,
          &:hover,
          &:active {
            color: #{$appTextcolor};
          }
        }
        &__desc {
          margin-top: 20px;
          line-height: 26px;
        }
      }
    }
  }
}

#{$pf}-icon-button {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  transition: color, background-color 0.3s ease;
  background-color: #{$appHeader};
  color: #{$appTextcolor};

  &:hover {
    color: #{$appHeaderitemhoverclr};
    cursor: pointer;
    background-color: $primary_color_ultra_light;
  }
  &--lg {
    height: 36px;
    width: 36px;
    font-size: 16px;
  }
}
#{$pf}-primary-button {
  padding: 10px 20px;
  background-color: $primary_color;
  border: none;
  color: $white;
  border-radius: 4px;
  font-size: 14px;
}

#{$pf}-title {
  &--lg {
    font-size: 42px;
  }
  &--md {
    font-size: 36px;
  }
  &--sm {
    font-size: 22px;
  }
  &--xs {
    font-size: 18px;
  }
}
#{$pf}-desc {
  font-size: 16px;
  line-height: 1.8;
}
#{$pf}-uc {
  margin-top: 100px;
  &-image {
    margin-top: 100px;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  main {
    padding: 0 2em;
  }
  #{$pf}-header {
    &-nav {
      display: none;
      &-ham {
        &-icon {
          display: inline-flex !important;
        }
      }
    }
  }
}
